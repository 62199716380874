import './RegisterForm.css';
import forget from "../images/forget.svg";
import { useTranslation, initReactI18next } from "react-i18next";
import React, { useState } from 'react';
import axios from 'axios';

function ForgotPassword() {
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://admin.cheretanet.com/api/reset-phone', {
        phone_number: phoneNumber,
      });

      console.log(response.data.message); // OTP code is sent to your phone
      setSuccessMessage('OTP code is sent to your phone.');
      setStep(2);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    }
  };

  const handleVerifySubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://admin.cheretanet.com/api/otp-verify', {
        otp: otp,
      });

      console.log(response.data.message); // OTP verified successfully
      setSuccessMessage('OTP verified successfully.');
      setStep(3);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://admin.cheretanet.com/api/update', {
        phone_number: phoneNumber,
        password: password,
        password_confirmation: confirmPassword,
      });

      console.log(response.data.message); // Password reset successful
      setSuccessMessage('Password reset successful.');
      // Handle success scenario, e.g., show a success message to the user
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className='compform'>
         <img src={forget} alt="FAQ" className="comp__image" />
   
     {errorMessage && <p>{errorMessage}</p>}
      {successMessage && <p>{successMessage}</p>}
      {step === 1 && (
        <form onSubmit={handlePhoneSubmit} className='RegisterFormClass'> 
        
          <div>
            <label htmlFor="phone_number">Phone Number:</label>
            <input type="text" id="phone_number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
          </div>
          <button type="submit" className='btn3'>Send OTP</button>
        </form>
      )}
      {step === 2 && (
        <form onSubmit={handleVerifySubmit} className='RegisterFormClass'>
          <div>
            <label htmlFor="otp">OTP:</label>
            <input type="text" id="otp" value={otp} onChange={(e) => setOtp(e.target.value)} required />
          </div>
          <button type="submit" className='btn3'>Verify OTP</button>
        </form>
      )}
      {step === 3 && (
        <form onSubmit={handleUpdateSubmit} className='RegisterFormClass'>
          <div>
            <label htmlFor="password">New Password:</label>
            <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <div>
            <label htmlFor="confirm_password">Confirm Password:</label>
            <input type="password" id="confirm_password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
          </div>
          <button type="submit" className='btn3'>Reset Password</button>
        </form>
      )}
    </div>
  );
}

export default ForgotPassword;