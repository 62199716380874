import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { QueryClient, QueryClientProvider, useMutation, useQuery } from 'react-query';
import './RegisterForm.css';
import { useTranslation } from "react-i18next";
import register from "../images/register.svg";
const queryClient = new QueryClient();

const RegisterUp = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [termData, setTermData] = useState([]);
   
  const fetchTermData = async () => {
    const response = await axios.get('https://admin.cheretanet.com/api/term');
    setTermData(response.data);
  };
  
  useEffect(() => {
    fetchTermData();
  }, []);

  const { data: term } = useQuery('term', fetchTermData);

  const [selectedTerm, setSelectedTerm] = React.useState('');

  const handleTermChange = (event) => {
    setSelectedTerm(event.target.value);
  };

  const mutation = useMutation((formData) => {
    let url = '';

    switch (step) {
      case 1:
        url = 'https://admin.cheretanet.com/api/registers';
        break;
      case 2:
        url = 'https://admin.cheretanet.com/api/verify-otp';
        break;
      case 3:
        url = 'https://admin.cheretanet.com/api/company-info';
        break;
      case 4:
        url = 'https://admin.cheretanet.com/api/payment-info';
        break;
      default:
        throw new Error('Invalid step');
    }

    return axios.post(url, formData)
      .then((response) => {
        // Move to the next step
        setStep(step + 1);
        return response.data;
      })
      .catch((error) => {
        throw new Error('Error');
      });
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage('');
    mutation.mutate({ ...formData, term: selectedTerm, paymentInfo: true }); // Include the selected term and paymentInfo flag in the form data
  }
  const handlePaymentSuccess = () => {
    console.log('handlePaymentSuccess is being called');
    if (mutation.data && mutation.data.redirect_url && mutation.data.redirect_url.data && mutation.data.redirect_url.data.checkout_url) {
      window.location.href = mutation.data.redirect_url.data.checkout_url;
    } else {
      console.log('Redirect URL is missing in the mutation data.');
    }
  };
  useEffect(() => {
    if (mutation.isSuccess) {
      console.log('Redirection is being triggered');
      handlePaymentSuccess();
    }
  }, [mutation.isSuccess, mutation.data]);

  const renderStepOne = () => (
     <div className='compform'>
     <img src={register} className="comp__image" alt="logo" />
       <form onSubmit={handleSubmit} className='RegisterFormClass'>
       <h2> {t("userInfo")}</h2>
       <br/>
       <br/>
         <label>
         {t("name")} :<br></br> 
           <input type="text" name="name" required onChange={handleInputChange} />
         </label>
         <label>
       { t( "email")} :<br></br>
           <input type="email" name="email" required onChange={handleInputChange} />
         </label>
         <label>
       { t( "password")}
           <input type="password" name="password" required onChange={handleInputChange} />
         </label>
         <label>
       {t ( "confirm")}
           <input type="password" name="password_confirmation" required onChange={handleInputChange} />
         </label>
         <label>
         {t("phone_no")}
           <input type="tel" name="phone_number" required onChange={handleInputChange} />
         </label>
         <button type="submit" className ="btn3">{t("next")}</button>
    
       </form>
    </div>
  );

  const renderStepThree = () => (
    <div className='compform'> 
        <img src={register} className="comp__image" alt="logo" />
    <form onSubmit={handleSubmit} className='RegisterFormClass'>
      <h2>{t("compInfo")}</h2>
      <label>
        {t("companyname")} :
        <input type="text" name="company_name" required onChange={handleInputChange} />
      </label>
      <label>
        {t("description")}:
        <textarea name="description" required onChange={handleInputChange}></textarea>
      </label>
      <label>
        {t("locations")}:
        <input type="text" name="location" required onChange={handleInputChange} />
      </label>
      <label>
        {t("phone_no")}
        <input type="tel" name="phone_number" required onChange={handleInputChange} />
      </label>
      <label>
        {t("company_logo")}
        <input type="file" name="company_logo" required onChange={handleFileChange} />
      </label>
      <label>
        {t("promotion_img")}
        <input type="file" name="promotion_image" required onChange={handleFileChange} />
      </label>
      <button type="submit" className="btn3">{t("next")}</button> &nbsp;&nbsp;
      <button type="button" className="btn3" onClick={() => setStep(step + 1)}>
        {t("skip")}
      </button>
    </form>
  </div>
  );

  const renderStepFour = () => (
    <div className='compform'>
        <img src={register} className="comp__image" alt="logo" />
      <form onSubmit={handleSubmit} className='RegisterFormClass'>
        <h5> {t("Payment")} </h5>
        <label>
        {t("name")}
          <input type="text" name="full_name" required onChange={handleInputChange} />
        </label>
        <label>
        {t("term")}
        <select className="select-dropdown" id="term-select" value={selectedTerm} onChange={handleTermChange}>
  {termData && termData.map((term, index) => (
    <option key={index} value={term}>{term}</option>
  ))}
</select>
        </label>
          <label>
         {t("phone_no")}
           <input type="tel" name="phone_number" required onChange={handleInputChange} />
         </label>
        {/* Rest of the form */}
        <button type="submit" className="btn3">{t("next")}</button> 
    
      </form>
    </div>
  );

  const renderStepTwo = () => (
    <div className='compform'>
        <img src={register} className="comp__image" alt="logo" />
      <form onSubmit={handleSubmit} className='RegisterFormClass'>
        <h2> {t("otp")}</h2>
        <br/>
        <br/>
        <label>
         {t("otp")}
          <input type="text" name="otp" required onChange={handleInputChange} />
        </label>
       
        {/* Rest of the form */}
        <button type="submit" className="btn3">{t("next")}</button>
     
      </form>
    </div>
  );

  const renderErrorMessage = () => <div>{errorMessage}</div>;

  return (
    <div>
      {step === 1 && renderStepOne()}
      {step === 2 && renderStepTwo()}
      {step === 3&& renderStepThree()}
      {step === 4 && renderStepFour()}
      {mutation.isLoading && <div>Loading...</div>}
      {mutation.isError && <div>Error occurred.</div>}
      {mutation.isSuccess && <div>Success!</div>}
      {renderErrorMessage()}
    </div>
  );
};

function Register() {
  return (
    <QueryClientProvider client={queryClient}>
      <RegisterUp />
    </QueryClientProvider>
  );
}

export default Register;