import React from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const LogoutButton = ({ onLogout }) => {
  const { t } = useTranslation();
    return (
      <button onClick={onLogout}>
        {t("logout")}
      </button>
    );
    

  };

 

const LogOut = () => {
  const handleLogout = () => {

    // Remove the user ID from Local Storage
    localStorage.removeItem('user_id');
    //navigate('/');   
    window.location.href = '/';
    // Other logout logic or state updates
  };
  const navigate = useNavigate();
  return (
    <div>
      {/* Other application content */}
      <LogoutButton onLogout={handleLogout} />
    </div>
  );
};

export default LogOut;