import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import register from "../images/register.svg";

function PaymentForm() {
  const { t } = useTranslation();

  const [fullName, setFullName] = useState('');
  const [termOptions, setTermOptions] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Fetch the term options from the API
    axios
      .get('https://admin.cheretanet.com/api/term')
      .then(response => {
        setTermOptions(response.data);
        setSelectedTerm(response.data[0]); // Set the default selected term
      })
      .catch(error => {
        console.error('Error fetching term options:', error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const paymentData = {
      full_name: fullName,
      term: selectedTerm,
      phone_number: phoneNumber,
    };

    try {
      const response = await axios.post(
        "https://admin.cheretanet.com/api/payment-info",
        paymentData
      );

      if (response.status === 200) {
        const responseData = response.data;
        const redirectURL =
          responseData.redirect_url.data.checkout_url;
        window.location.href = redirectURL;
        setSuccessMessage('Redirecting to chapa...');
      } else {
        setErrorMessage('Payment request failed. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div className='compform'>
      <img src={register} className="comp__image" alt="logo" />
      <form onSubmit={handleSubmit} className='RegisterFormClass'>
        <h5> {t("Payment")} </h5>
        <label>
        {t("name")}
        <input
          type="text"
          value={fullName}
          onChange={e => setFullName(e.target.value)}
          placeholder="Full Name"
          required
        />
         </label>
         <label>
        {t("term")}   </label>
        <select
          value={selectedTerm}
          onChange={e => setSelectedTerm(e.target.value)}
          required
        >
          {termOptions.map((term, index) => (
            <option key={index} value={term}>
              {term}
            </option>
          ))}
        </select>
      
        <label>
        {t("phone_no")}
        <input
          type="text"
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          placeholder="Phone Number"
          required
        /> </label>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default PaymentForm;