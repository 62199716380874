import React, { useState } from 'react';
import './Contactus.css';
import { ImLocation } from 'react-icons/im';
import { BsMailbox } from 'react-icons/bs';
import { BsFillTelephonePlusFill } from 'react-icons/bs';
import { MdOutlineFax } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';
import contact from "../images/contactus.svg";
import { useTranslation } from "react-i18next";
import axios from 'axios';

function Contactus() {

  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [phone_number, setphone_number] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setphone_number(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = { name, phone_number, message };
    const token = localStorage.getItem('token')
    axios.post('https://admin.cheretanet.com/api/contactus', data, {
      headers: {
        'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        if (response.data.success) {
          setSuccessMessage('Your message has been sent. We will get back to you as soon as possible.');
        } else {
          setErrorMessage('Your message has been sent. We will get back to you as soon as possible.');
        }
      })
      .catch(error => {
        setErrorMessage('An error occurred while sending your message. Please try again later.');
      });
  };

  return (
    <div className='allpage'>
      <img src={contact} alt="contact" className="contact-image" />
      <div className="contact-us">
        <div className="contact-info">
          <h2>{t("contact_info")}</h2>
          <p><ImLocation className="icon" size="25px" />&nbsp;&nbsp;&nbsp;{t("location")}</p>
          <p><BsMailbox className="icon" size="25px" />&nbsp;&nbsp;&nbsp;{t("po_box")}: 100074</p>
          <p><BsFillTelephonePlusFill className="icon" size="25px" />&nbsp;&nbsp;&nbsp; +251-911-20-26-11</p>
          <p><MdOutlineFax className="icon" size="25px" />&nbsp;&nbsp;&nbsp;{t("fax")}: +251-1-15-51-33-54</p>
          <p><BsFillTelephonePlusFill className="icon" size="25px" />&nbsp;&nbsp;&nbsp;{t("technical_support")}: 6949</p>
          <p><MdEmail className="icon" size="25px" />&nbsp;&nbsp;&nbsp;contact@cheretanet.com</p>
        </div>

        <div className="contact-form">
          <h2>{t("contact")}</h2>
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">{t("name")}:</label>
              <input type="text" id="name" name="name" value={name} onChange={handleNameChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="phone_number">{t("phone_no")}:</label>
              <input type="tel" id="phone_number" name="phone_Number" value={phone_number} onChange={handlePhoneNumberChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="message">{t("message")}:</label>
              <textarea id="message" name="message" value={message} onChange={handleMessageChange} required />
            </div>
            <button type="submit">{t("send")}</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contactus;