import React, { useState } from 'react';
import axios from 'axios';
import "./RegisterForm.css";
import comp from "../images/comp.svg";
import { useTranslation, initReactI18next } from "react-i18next";

const CompanyReg = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    company_name: '',
    description: '',
    location: '',

    phone_no: '',
    company_logo: null,
    promotion_image: null,
    work_license: null,
    owners_name: '', // Add the owners_name field
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    if (e.target.type === 'file') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [e.target.name]: e.target.files[0]
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [e.target.name]: e.target.value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if user ID is stored in Local Storage
    const userId = localStorage.getItem('user_id');
  
    if (userId) {
      // User ID is found in Local Storage, proceed with registration
  
      const postData = new FormData();
      postData.append('company_name', formData.company_name);
      postData.append('description', formData.description);
      postData.append('location', formData.location);
      postData.append('phone_no', formData.phone_no);
      postData.append('company_logo', formData.company_logo);
      postData.append('promotion_image', formData.promotion_image);
      postData.append('work_license', formData.work_license);
      postData.append('owners_name', formData.owners_name);
       const token = localStorage.getItem('token')
      try {
        const response = await axios.post('https://admin.cheretanet.com/api/company/create', postData, {
          headers: {
            'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
          }
        });
      
        if (response.status === 200) {
          setSuccessMessage('Registration successful!');
          // Additional logic or state updates upon successful submission
        } else {
          setErrorMessage('Registration failed.');
          // Handle the failed registration
        }
      } catch (error) {
        console.error(error);
        setErrorMessage('An error occurred. Please try again later.');
        // Handle error and display error message
      }
    } else {
      // User ID is not found in Local Storage, disallow registration
      setErrorMessage('User ID not found. Please log in to register the company.');
    }
  }

  return (
    <div className='compform'>
         <img src={comp} alt="FAQ" className="comp__image" />
   
    <form onSubmit={handleSubmit} className='RegisterFormClass'>
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
   <h3>Regitster your company!</h3><br/><br/>
      <div>
        <label>{t("companyname")}:</label>
        <input
          type="text"
          name="company_name"
          value={formData.company_name}
          onChange={handleChange}
          required
        />
      </div>

      <div>
        <label>{t("description")}:</label><br></br>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
        ></textarea>
      </div>

      <div>
        <label>{t("locations")}:</label>
        <input
          type="text"
          name="location"
          value={formData.location}
          onChange={handleChange}
          required
        />
      </div>

      <div>
        <label>{t("phone_no")}:</label>
        <input
          type="tel"
          name="phone_no"
          value={formData.phone_no}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>{t( "owner")}:</label>
        <input
          type="text"
          name="owners_name"
          value={formData.owners_name}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>{t("company_logo")}:</label>
        <input
          type="file"
          name="company_logo"
          onChange={handleChange}
          required
        />
      </div>

      <div>
        <label>{t("promotion_img")} :</label>
        <input
          type="file"
          name="promotion_image"
          onChange={handleChange}
          required
        />
      </div>

      <div>
        <label>{t("work_license")}:</label>
        <input
          type="file"
          name="work_license"
          onChange={handleChange}
          required
        />
      </div>

      <button type="submit" className="btn3">{t("submit")}</button>
    </form>
    </div>
  );
};

export default CompanyReg;