import React from 'react';

import axios from 'axios';
import './Home.css';
import { FaSearch } from 'react-icons/fa';
import Pagination from './Pagination.js';
import { useTranslation, initReactI18next } from 'react-i18next';
import Searchbar from './SearchBar.js';
import { useQuery } from 'react-query';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const TenderCard = ({ tender }) => {
  const [showDetails, setShowDetails] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const handleButtonClick = () => {
    setShowDetails(!showDetails);
  };

 
  const handleSave = async () => {
    try {
      const userId = localStorage.getItem('user_id');
      const token = localStorage.getItem('token');

      const tenderId = tender.id;
  
      if (!userId) {
        console.error('User ID not found. Please log in.');
        return;
      }
      const requestData = {
        user_id: parseInt(userId),
        tender_id: tenderId
      };
      const response = await axios.post('https://admin.cheretanet.com/api/saveTender', requestData, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
  
      if (response.status === 200) {
        setSaved(true);
        console.log('Tender saved successfully');
      } else {
        console.error('Error saving tender');
      }
    } catch (error) {
      console.error('Error saving tender:', error);
    }
  };

  return (
    <div className="card">
      <h4 className="title">{tender.title}</h4> <hr />
      <p className="deadline" dangerouslySetInnerHTML={{ __html: ` ${tender.summernote}` }} />

      {showDetails && (
        <div className="details">
          <p className="deadline">Start Date: {tender.start_date}</p>
          <p className="deadline">End Date: {tender.end_date}</p>
        </div>
      )}
      <div className="button-container">
        
        {showDetails ? (
          <button className="btn2" onClick={handleButtonClick}>
            Hide Details
          </button>
        ) : ( 
          <button className="btn2" onClick={handleButtonClick}>
            Open
          </button>
        )}
        <br />
        {!saved && <button className="btn2" onClick={handleSave}>Save</button>}
        {saved && <span> saved</span>}
      </div>
    </div>
  );
};

const MyComponent = ({ data }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const cardsPerPage = 3;

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      {currentCards.map((tender) => (
        <TenderCard key={tender.id} tender={tender} />
      ))}

      <Pagination
        currentPage={currentPage}
        cardsPerPage={cardsPerPage}
        totalCards={data.length}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

function TendersListNet() {
  const { t } = useTranslation();
  const token = localStorage.getItem('token');

  const { isLoading, data } = useQuery('tenders', () =>
  axios.get('https://admin.cheretanet.com/api/tenders', {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }).then((response) => response.data)
);

  const { isLoading: isLoadingAds, data: adImages } = useQuery('adImages', () =>
    axios.get('https://admin.cheretanet.com/api/promotion',{
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then((response) => response.data)
  );

  if (isLoading || isLoadingAds) {
    return <div>Loading...</div>;
  }

  return (
    
    <div className="tenders-list">
      <div className="ad-container">
    
      <div className="column">
          {adImages.slice(0, 1).map((imageUrl, index) => (
            <img key={index}  src={`https://admin.cheretanet.com/public/images/${imageUrl}`} alt="Ad Placeholder" className="ad-image"
            style={{
              width: '300px',  // Set the desired width
              height: '295px' // Set the desired height
              
            }} />
          ))}<br></br><br></br>
        </div> 
        <div className="column">
          {adImages.slice(2, 3).map((imageUrl, index) => (
            <img key={index}  src={`https://admin.cheretanet.com/public/images/${imageUrl}`} alt="Ad Placeholder" className="ad-image " 
            style={{
              width: '300px',  // Set the desired width
              height: '295px' // Set the desired height
            }} /> 
          ))}<br></br><br></br>
        </div>    
      </div>
      
      <div className="tenders-container">
        <Searchbar />
        <div className="card-wrapper">
          <MyComponent data={data} />
        </div>
      </div>
      <div className="ad-container">
       <div className="column">
          {adImages.slice(1, 2).map((imageUrl, index) => (
            <img key={index}  src={`https://admin.cheretanet.com/public/images/${imageUrl}`} alt="Ad Placeholder" className="ad-image" 
            style={{
              width: '300px',  // Set the desired width
              height: '295px' ,// Set the desired height
              padding: '50px'
            }}/>
          ))}<br></br><br></br>
        </div>
        <div className="column">
          {adImages.slice(3, 4).map((imageUrl, index) => (
            <img key={index}
  src={`https://admin.cheretanet.com/public/images/${imageUrl}`} alt="Ad Placeholder"
  className="ad-image"
  style={{
    width: '300px',  // Set the desired width
    height: '295px' // Set the desired height
  }}
/>          ))}<br></br><br></br>
          </div>
        </div>
    </div>
  );
}

function TendersList() {
  return (
    <QueryClientProvider client={queryClient}>
      <TendersListNet />
    </QueryClientProvider>
  );
}

export default TendersList;