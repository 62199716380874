
import React, { useState } from 'react';
import axios from 'axios';
import profile from "../images/profile.svg";
import "./RegisterForm.css";
import { useQuery } from 'react-query';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();
const EditProfileNet = ({ userId }) => {
  const [formValues, setFormValues] = useState({ name: '', email: '', password: '', passwordConfirmation: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const fetchUserProfile = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://admin.cheretanet.com/api/user/data', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Error occurred while fetching user profile.');
    }
  };

  const { data: profileData, isLoading, error } = useQuery(['userProfile', userId], () => fetchUserProfile(userId));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', formValues.name);
      formData.append('email', formValues.email);
      formData.append('password', formValues.password);
      formData.append('password_confirmation', formValues.passwordConfirmation); // Add password confirmation field

      const token = localStorage.getItem('token');

      const response = await axios.post('https://admin.cheretanet.com/api/profile/edit', formData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });

      setSuccessMessage('Profile updated successfully.');
      setErrorMessage('');

      console.log('Profile updated successfully:', response.data);
      // Handle success notification or redirection
    } catch (error) {
      setSuccessMessage('');
      setErrorMessage('Error updating user profile.');

      console.error('Error updating user profile:', error);
      // Handle error notification
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='compform'>
    
      <img src={profile} className="comp__image" alt="logo" />
      <form onSubmit={handleSubmit} className="RegisterFormClass"> 
    
        <h3>User Profile</h3>
        <br></br>
        <br></br>
        <p>Name: {profileData.user.name}</p>
        <p>Email: {profileData.user.email}</p>
        <hr></hr>
        <br></br>
        <h5>Change your Profile</h5>
        <br></br>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
        <label>
          Name:
          <input type="text" name="name" value={formValues.name} onChange={handleInputChange} />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={formValues.email} onChange={handleInputChange} />
        </label>
        <label>
          Password:
          <input type="password" name="password" value={formValues.password} onChange={handleInputChange} />
        </label>
        <label>
          Confirm Password:
          <input
            type="password"
            name="passwordConfirmation"
            value={formValues.passwordConfirmation}
            onChange={handleInputChange}
          />
        </label>
        <button type="submit" className="btn3">Save Changes</button>
      </form>
    </div>
  );
};

function EditProfile() {
  return (
    <QueryClientProvider client={queryClient}>
      <EditProfileNet />
    </QueryClientProvider>
  );
}

export default EditProfile;