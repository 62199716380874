import React, { useState } from 'react';
import './PostTender.css';
import Tenderimage from '../images/tender-icon.svg';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, useQuery, QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';

const queryClient = new QueryClient();
const token = localStorage.getItem('token')
const fetchCategories = async () => {
  const response = await axios.get('https://admin.cheretanet.com/api/categories' ,{
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
  return response.data;
};

const submitTender = async ({ title, description, category, startdate, enddate }) => {
  try {
    const response = await axios.post('https://admin.cheretanet.com/api/stores', {
      title: title,
      summernote: description,
      category_id: category,
      start_date: startdate,
      end_date: enddate
    }, {
      headers: {
        'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
      }
    });
    return response.data;
  } catch (error) {
    console.error(error);
    // Handle the error or display an error message
    throw error; // Optional: Rethrow the error to be handled by the caller
  }
};

const PostTenderNet = () => {
  const { t } = useTranslation();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [startdate, setStartdate] = useState('');
  const [enddate, setEnddate] = useState('');
  const [error, setError] = useState('');
  const [successfull, setSuccessfully] = useState('');

  const queryClient = useQueryClient();
  const { mutate: handleSubmit } = useMutation(submitTender, {
    onSuccess: () => {
      setSuccessfully('Tender submitted successfully');
      setTitle('');
      setDescription('');
      setCategory('');
      setStartdate('');
      setEnddate('');
      setError('');
      
    },
    onError: (error) => {
      setError('Error submitting tender');
    }
  });

  const { data: categories } = useQuery('categories', fetchCategories);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleStartdateChange = (e) => {
    setStartdate(e.target.value);
  };

  const handleEnddateChange = (e) => {
    setEnddate(e.target.value);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const user_id = localStorage.getItem('user_id');

    if (user_id) {
      handleSubmit({ title, description, category, startdate, enddate });
    } else {
      setError('User ID not found');
    }
  };

  return (
    <div className="tender-form-container">
     
      <form className="tender-form" onSubmit={handleSubmitForm}>
      {error && <p className="error-message">{error}</p>}
      {successfull && <p className="error-message">{successfull}</p>}
        <br/>
        <br/>
        <h2 className="form-title">
          {t('post_tender')} &nbsp;&nbsp;
        </h2>

        <div className="form-group">
          <br/>
          <br/>
          <label htmlFor="title"></label>
          <input type="text" id="title" placeholder={t('title')} name="title" value={title} onChange={handleTitleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="description"></label>
          <textarea
            id="description"
            placeholder={t('description')}
            name="description"
            value={description}
            onChange={handleDescriptionChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="category-select">Select Category</label>
          <div className="select-container">
            <br />
            <select className="select-dropdown" id="category-select" value={category} onChange={handleCategoryChange}>
              {categories &&
                categories.map((category) => (
                  <option className="select-option" key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="deadline">{t('starting_date')}</label>
          <input
            type="date"
            id="deadline"
            placeholder={t('starting_date')}
            name="deadline"
            value={startdate}
            onChange={handleStartdateChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="deadline">{t('ending_date')}</label>
          <input
            type="date"
            id="deadline"
            placeholder={t('ending_date')}
            name="deadline"
            value={enddate}
            onChange={handleEnddateChange}
          />
        </div>
        <button type="submit" className="submit-button">
          {t('post')}
        </button>
      </form>
      <img src={Tenderimage} className="tender__image" alt="logo" />
    </div>
  );
};

function PostTender() {
  return (
    <QueryClientProvider client={queryClient}>
      <PostTenderNet />
    </QueryClientProvider>
  );
}

export default PostTender;