import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();
function JobsList() {
  const { data: jobs, isLoading, isError, error } = useQuery('jobs', fetchJobs);
  const token = localStorage.getItem('token');
  async function fetchJobs() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };

    const response = await axios.get('https://admin.cheretanet.com/api/job', config); 
    return response.data;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
<h5 style={{ marginLeft: '45%' }}>Jobs List</h5>
      {jobs.length === 0 ? (
        <p>No jobs available</p>
      ) : (
        <ul >
          {jobs.map((job) => (
            <ul className='cardAlt' key={job.id}>{job.title}<hr></hr>
            <p dangerouslySetInnerHTML={{ __html: ` ${job.description}` }} />

            </ul>
          ))}
        </ul>
      )}
    </div>
  );
}
function Job() {
    return (
      <QueryClientProvider client={queryClient}>
        <JobsList/>
      </QueryClientProvider>
    );
  }
  
  export default Job;