import { useQuery } from 'react-query';
import { QueryClient, QueryClientProvider } from 'react-query';
import React from 'react';
import Pagination from './Pagination';
import axios from 'axios';

const queryClient = new QueryClient();

function AlertDisplayComponent() {
  const [showDetails, setShowDetails] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const cardsPerPage = 3;

  const handleButtonClick = () => {
    setShowDetails(!showDetails);
  };

  const token = localStorage.getItem('token');
  const { data, isLoading, isError, error } = useQuery('tenders', async () => {
    const response = await fetch('https://admin.cheretanet.com/api/alert/display', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch tenders');
    }

    return response.json();
  });

  if (isLoading) {
    return <p>Loading tenders...</p>;
  }

  if (isError) {
    return <p>Error: {error.message}</p>;
  }

  const tenders = data.data;

  if (tenders.length === 0) {
    return <p>Create Your Alert First</p>;
  }

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = tenders.slice(indexOfFirstCard, indexOfLastCard);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      {currentCards.map((tender) => (
        <div key={tender.id} className="cardAlt">
          <h3 className="title">{tender.title}</h3>
          <hr className="divider" />
          <p className="deadline" dangerouslySetInnerHTML={{ __html: ` ${tender.summernote}` }} />

          {showDetails && (
            <div className="details">
              <p className="deadline">Start Date: {tender.start_date}</p>
              <p className="deadline">End Date: {tender.end_date}</p>
            </div>
          )}

          <div className="button-container">
            {showDetails ? (
              <button className="btn2" onClick={handleButtonClick}>
                Hide Details
              </button>
            ) : (
              <button className="btn2" onClick={handleButtonClick}>
                Open
              </button>
            )}
          </div>
        </div>
      ))}

      <Pagination
        currentPage={currentPage}
        cardsPerPage={cardsPerPage}
        totalCards={tenders.length}
        onPageChange={handlePageChange}
      />
    </div>
  );
  
}

function AlertDisplay() {
  return (
    <QueryClientProvider client={queryClient}>
      <AlertDisplayComponent />
    </QueryClientProvider>
  );
}

export default AlertDisplay;





// import React from 'react';

// import axios from 'axios';
// import './Home.css';
// import { FaSearch } from 'react-icons/fa';
// import Pagination from './Pagination.js';
// import { useTranslation, initReactI18next } from 'react-i18next';
// import Searchbar from './SearchBar.js';
// import { useQuery } from 'react-query';
// import { QueryClient, QueryClientProvider } from 'react-query';

// const queryClient = new QueryClient();

// const TenderCard = ({ tender }) => {
//   const [showDetails, setShowDetails] = React.useState(false);
//   const handleButtonClick = () => {
//     setShowDetails(!showDetails);
//   };

//   return (
//     <div className="card">
//       <h4 className="title">{tender.title}</h4> <hr />
//       <p className="deadline">{tender.summernote}</p>

//       {showDetails && (
//         <div className="details">
//           <p className="deadline">Start Date: {tender.start_date}</p>
//           <p className="deadline">End Date: {tender.end_date}</p>
//         </div>
//       )}
//       <div className="button-container">
//         {showDetails ? (
//           <button className="btn2" onClick={handleButtonClick}>
//             Hide Details
//           </button>
//         ) : (
//           <button className="btn2" onClick={handleButtonClick}>
//             Open
//           </button>
//         )}
//         <br />
//       </div>
//     </div>
//   );
// };
// const MyComponent = ({ data }) => {
//   const [currentPage, setCurrentPage] = React.useState(1);
//   const cardsPerPage = 3;

//   const indexOfLastCard = currentPage * cardsPerPage;
//   const indexOfFirstCard = indexOfLastCard - cardsPerPage;
//   const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <div>
//       {currentCards.map((tender) => (
//         <TenderCard key={tender.id} tender={tender} />
//       ))}

//       <Pagination
//         currentPage={currentPage}
//         cardsPerPage={cardsPerPage}
//         totalCards={data.length}
//         onPageChange={handlePageChange}
//       />
//     </div>
//   );
// };

// function TendersListNet() {
//   const { t } = useTranslation();
//   const token = localStorage.getItem('token');

//   const { isLoading, data } = useQuery('tenders', async () => {
//     try {
//       const response = await axios.get('https://admin.cheretanet.com/api/alert/display', {
//         headers: {
//           'Content-Type': 'application/json',
//           'Accept': 'application/json',
//           'Authorization': 'Bearer ' + token
//         }
//       });
//       return response.data;
//     } catch (error) {
//       throw new Error('Error fetching tenders');
//     }
//   });

//   const { isLoading: isLoadingAds, data: adImages } = useQuery('adImages', () =>
//     axios.get('https://admin.cheretanet.com/api/promotion', {
//       headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json',
//         'Authorization': 'Bearer ' + token
//       }
//     }).then((response) => response.data)
//   );

//   if (isLoading || isLoadingAds) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="tenders-list">
//       <div className="ad-container">
//         <div className="column">
//           {adImages.slice(0, 1).map((imageUrl, index) => (
//             <img 
//               key={index} 
//               src={`https://admin.cheretanet.com/public/images/${imageUrl}`} 
//               alt="Ad Placeholder" 
//               className="ad-image"
//               style={{
//                 width: '300px',
//                 height: '295px'
//               }}
//             />
//           ))}<br /><br />
//         </div> 
//         <div className="column">
//           {adImages.slice(2, 3).map((imageUrl, index) => (
//             <img 
//               key={index} 
//               src={`https://admin.cheretanet.com/public/images/${imageUrl}`} 
//               alt="Ad Placeholder" 
//               className="ad-image" 
//               style={{
//                 width: '300px',
//                 height: '295px'
//               }}
//             /> 
//           ))}<br /><br />
//         </div>    
//       </div>
      
//       <div className="tenders-container">
       
//         {data && data.length > 0 ? (
//           <div className="card-wrapper">
//             <MyComponent data={data} />
//           </div>
//         ) : (
//           <div>No Alert Created</div>
//         )}
//       </div>
      
//       <div className="ad-container">
//         <div className="column">
//           {adImages.slice(1, 2).map((imageUrl, index) => (
//             <img 
//               key={index} 
//               src={`https://admin.cheretanet.com/public/images/${imageUrl}`} 
//               alt="Ad Placeholder" 
//               className="ad-image" 
//               style={{
//                 width: '300px',
//                 height: '295px',
//                 padding: '50px'
//               }}
//             />
//           ))}<br /><br />
//         </div>
//         <div className="column">
//           {adImages.slice(3, 4).map((imageUrl, index) => (
//             <img 
//               key={index}
//               src={`https://admin.cheretanet.com/public/images/${imageUrl}`} 
//               alt="Ad Placeholder"
//               className="ad-image"
//               style={{
//                 width: '300px',
//                 height: '295px'
//               }}
//             />
//           ))}<br /><br />
//         </div>
//       </div>
//     </div>
//   );
// }

// function TendersList() {
//   return (
//     <QueryClientProvider client={queryClient}>
//       <TendersListNet />
//     </QueryClientProvider>
//   );
// }

// export default TendersList;