import React, { useState } from 'react';
import './Footer.css';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaTelegram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axios from 'axios';

function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const user_id = localStorage.getItem('user_id'); // Get user_id from localStorage

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make an API call to send the email
      const response = await axios.post('https://admin.cheretanet.com/api/email-subscription', { email });
      if (response.status === 200) {
        setSuccessMessage('Subscribe successful!');
        // Additional logic or state updates upon successful submission
      } else {
        setErrorMessage('Subscribe failed.');
        // Handle the failed registration
      }
      // Handle the response (e.g., show success message, clear form, etc.)
      
      console.log(response.data); // You can customize the handling based on your requirements
      setEmail('');
    } catch (error) {
      console.error(error); // Handle the error appropriately
    }
  };


return (
  <footer className="footer">
    <div className="content">
    <div className="links">
          <Link to="/">{t("home")}</Link>
          <Link to="./Aboutus.js">{t("about_us")}</Link>
          <Link to="./Contactus.js">{t("contact")}</Link>
          <Link to="./Aboutus.js">{t("faq")}</Link>
          <Link to="./PostTender.js">{t("post_tender")}</Link>

          

        </div>
        <div className="phonebook">
          <Link to="./Phonebook.js">{t("phonebook")}</Link>
          <Link to="./CompanyReg.js">{t("companyreg")}</Link>
          
          {user_id && (
                  <React.Fragment>
                   
                   <Link to="/AlertCat.js">{t("CreateAlert")}</Link>
                   <Link to="./Job.js">{t("job")}</Link>
                   <Link to="./PaymentForm.js">{t("Payment")}</Link>
                   
                  </React.Fragment>
                )}
         
        </div>
      <div className="subscribe">
        <h3>{t("subscribe")}</h3>
        <p>{t("subscribe_below")}</p>
        <form onSubmit={handleSubmit}>
        {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
          <input
            type="email"
            placeholder={t("enter_email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit">{t("subscribe")}</button>
        </form>
      </div>
      <div className="social-media">
          <h3>{t("follow_us")}</h3>
          <ul>
            <li><a href="https://www.facebook.com/profile.php?id=61550016956994&mibextid=ZbWKwL">< FaFacebook/> </a></li>
            <li><a href="https://twitter.com/micro_sun_soln?s=09"> <FaTwitter/></a></li>
            <li><a href="https://instagram.com/micro_sun_and_solution?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"><FaInstagram/></a></li>
            <li><a href="https://www.linkedin.com/company/micro-sun-solution/"><FaLinkedin/></a></li>
            <li><a href="https://t.me/micro_sun_and_solution"><FaTelegram/></a></li>
          </ul>
         
        </div>
          
    </div>
    <div className='mss'>
      <p>&copy; Micro Sun &amp; Solution {currentYear}, All Rights Reserved</p>
    </div>
  </footer>
);
}

export default Footer;