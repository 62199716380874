import logo from '../images/cn.png';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { FaHome, FaPhone, FaUser, FaInfoCircle, FaBars } from "react-icons/fa";
import { FiSettings } from 'react-icons/fi';
import { useTranslation, initReactI18next } from "react-i18next";
import Css from "./Header.css";
import Person from "../images/person.jpg";
import settings from "../images/settings.webp";
import axios from 'axios';
import DarkMode from './DarkMode.js';
import Languageselector from './Languageselector.js';
import "./SearchBar.css";
import LogOut from "./LogOut"
import FreeTender from "./FreeTender"

function Info() {
  return (
    <div className='info'>
      <p>+251-911-20-26-11</p>
    </div>
  )
}

function Header() {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [categories, setCategories] = useState([]);
  const token = localStorage.getItem('token')
  const user_id = localStorage.getItem('user_id'); // Get user_id from localStorage
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 920);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClick = (e) => {
    setShowMenu(!showMenu);
  };

  function CategoriesList() {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    
  
    useEffect(() => {
      const fetchCategories = async () => {
        try {
          const response = await axios.get('https://admin.cheretanet.com/api/categories', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
            },
          });
          setCategories(response.data);
          setIsLoading(false);
        } catch (error) {
          setError(error);
          setIsLoading(false);
        }
      };
  
      fetchCategories();
    }, []);
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error occurred while fetching categories.</div>;
    }
    const handleCategoryClick = (category) => {
      const updatedCategories = categories.map((cat) => ({
        ...cat,
        isExpanded: cat.id === category.id ? !cat.isExpanded : false,
      }));
      setCategories(updatedCategories);
    };
  
    return (
      <div>
    
        <ul className="subcategoriesList">
          {categories.map((category) => (
            <li key={category.id}>
             <p onClick={() => handleCategoryClick(category)} style={{ margin: 5, padding: 5, fontWeight: 'bold' }}> {category.name}</p>
              {category.isExpanded && (
                <ul className="subcategoriesList">
                  {category.subcategories.map((subcategory) => (
                    <li key={subcategory.id}  style={{ margin: 5, padding: 5 }}>{subcategory.name} </li>
                  ))}
                </ul>
              )}
            </li>
          
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div>
      <div className='info'>
        <Info />
      </div>

      <nav className="navbar1">
        <Link to="/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
        {isMobile ? (
          <FaBars className="menu-icon" onClick={handleMenuClick} />
        ) : (
            <div className="nav-container">
              <ul>
                <li>
                  <Link to="/">
                    <span>{t("home")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/Aboutus.js">
                    <span>{t("about_us")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/Contactus.js">
                    <span>{t("contact")}</span>
                  </Link>
                </li>
                <li className="dropdown">
                  <a href="#">
                    {t("tender")}
                  </a>
                  <div className="dropdown-content">
                  <Link to="/">{t("free_tenders")}</Link>
                    {user_id && (
                  <React.Fragment>
                   
                    <Link to="/Home.js">{t("all_tenders")}</Link>
                    <Link to="/SavedTender.js">{t("saved_tenders")}</Link>
                    <Link to="/AlertDisplay.js">{t("alert_tenders")}</Link>
                  </React.Fragment>
                )}
                  </div>
                </li>
                <li className="dropdown">
                  <a href="#">
                    {t("categories")}
                  </a>
                  <div className="dropdown-content">
                    <CategoriesList/>
                  {/* {categories.map((category) => (
                  <Link to={`/${category.slug}`} key={category.id}>
                    {category.name}
                  </Link>
                ))} */}
                  </div>
                </li>
                <li className="dropdown">
                  <img src={Person} className="person" alt="logo" />
                  <div className="dropdown-content">
  {!user_id && (
    <React.Fragment>
      <Link to="/Register.js"> <span>{t("register")}</span></Link>
      <Link to="/Login.js"> <span>{t("login")}</span></Link>
    </React.Fragment>
  )}
  {user_id && (
    <React.Fragment>
      <Link to="/EditProfile.js"> <span>{t("profile")}</span></Link>
      <LogOut/>
    </React.Fragment>
  )}
</div>

                </li>
                <DarkMode />
                <Languageselector />
              </ul>
            </div>
          )}
      </nav>
      {isMobile && showMenu && (
        <div className="mobile-menu">
          <ul>
            <li>
              <Link to="/">
                <span>{t("home")}</span>
              </Link>
            </li>
            <li>
              <Link to="/Aboutus.js">
                <span>{t("about_us")}</span>
              </Link>
            </li>
            <li>
              <Link to="/Contactus.js">
                <span>{t("contact")}</span>
              </Link>
            </li>
            <li>
              <Link to="#">
                {t("tender")}
              </Link>
              <div className="dropdown-content">
              <Link to="/">{t("free_tenders")}</Link>
                {user_id && (
                  <React.Fragment>
                    
                    <Link to="/Home.js">{t("all_tenders")}</Link>
                    <Link to="/SavedTender.js">{t("saved_tenders")}</Link>
                    <Link to="/AlertDisplay.js">{t("alert_tenders")}</Link>
                  </React.Fragment>)}
              </div>
            </li>
            <li>
              <Link to="#">
                {t("categories")}
              </Link>
              <div className="dropdown-content">
              <CategoriesList/>
                {/* {categories.map((category) => (
                  <Link to={`/${category.slug}`} key={category.id}>
                    {category.name}
                  </Link>
                ))} */}
              </div>
            </li>
            <li>
              <Link to="/">
                <img src={Person} className="person" alt="logo" />
              </Link>
              <div className="dropdown-content">
  {!user_id && (
    <React.Fragment>
      <Link to="/Register.js"> <span>{t("register")}</span></Link>
      <Link to="/Login.js"> <span>{t("login")}</span></Link>
    </React.Fragment>
  )}
  {user_id && (
    <React.Fragment>
      <Link to="/EditProfile.js"> <span>{t("profile")}</span></Link>
      <LogOut/>
    </React.Fragment>
  )}
</div>
            </li>
            <DarkMode />
            <Languageselector />
          </ul>
        </div>
      )}
    </div>
  );
}

export default Header;