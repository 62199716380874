import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import "./SearchBar.css";

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const userLoggedIn = localStorage.getItem('user_id');
const token = localStorage.getItem('token')
  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://admin.cheretanet.com/api/search/${searchQuery}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSearchResults(data);
      } else {
        setError('Error searching. Please try again.');
      }
    } catch (error) {
      setError('Error searching. Please try again.');
      console.error('Error searching:', error);
    } finally {
      setLoading(false);
    }
    
  };
  
const [showDetails, setShowDetails] = React.useState(false);
  const handleButtonClick = () => {
    setShowDetails(!showDetails);
  };
  

  return (
      <div className='searchh'>
        <input
          placeholder='Search Tender'
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        /> <button onClick={handleSearch}><FaSearch /></button><br></br>
       
        <div className='search-input'>
    
      {userLoggedIn ? (
        <>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className='search-results'>
                {searchResults.map((result) => (
                  <ul key={result.id}> 
                
                    {/* 
                        <h2>{result.title}</h2><hr></hr>
                        <p>Description: {result.summernote}</p>
                    <p>Start Date: {result.start_date}</p>
                    <p>End Date: {result.end_date}</p> */}
      <div className='card'>
      <h4 className="title">{result.title}</h4> <hr />
      <p className="deadline" dangerouslySetInnerHTML={{ __html: ` ${result.summernote}` }} />

      {showDetails && (
        <div className="details">
          <p className="deadline">Start Date: {result.start_date}</p>
          <p className="deadline">End Date: {result.end_date}</p>
        </div>
      )}

      <div className="button-container">
        
        {showDetails ? (
          <button className="btn2" onClick={handleButtonClick}>
            Hide Details
          </button>
        ) : ( 
          <button className="btn2" onClick={handleButtonClick}>
            Open
          </button>
        )}
        <br />
        </div>
      </div>
  
  
                    
                  </ul>
                ))}
             
            </div>
          )}
        </>
      ) : (
        <p></p>
      )}
    </div>
    </div>
  );
};

export default SearchBar;