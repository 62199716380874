import React from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import "./Phonebook.css";

const queryClient = new QueryClient();

const PhonebookNet = () => {
  const token = localStorage.getItem('token')
  const { data: companies, isLoading, isError } = useQuery('companies', async () => {
    const response = await axios.get('https://admin.cheretanet.com/api/phonebook', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    });
    return response.data;
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div>
      {companies.map((company) => (
        <CompanyCard key={company.id} company={company} />
      ))}
    </div>
  );
};

const CompanyCard = ({ company }) => {
  return (
    <div className="company-card">
        <h3>{company.company_name}</h3> <hr></hr>
      <img src={`https://admin.cheretanet.com/public/images/${company.company_logo}`} alt={company.company_name}  style={{
              width: '100px',  // Set the desired width
              height: '99px' // Set the desired height
              
            }} />   
       <p>Description:</p><p dangerouslySetInnerHTML={{ __html: ` ${company.description}` }} />

      <p>Location: {company.location}</p>
      <p>Phone_no: {company.phone_no}</p>
    </div>
  );
};

const Phonebook = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <PhonebookNet />
    </QueryClientProvider>
  );
};

export default Phonebook;