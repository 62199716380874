import React, { Component }  from 'react';
import i18n, { use } from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import { useState } from 'react';


const languages= [{
    code:'am',
    name: 'Amharic',
   
  },
  
  {
    code:'en',
    name: 'English',
   
  },

  {
    code:'or',
    name: 'Oromifa',
 
  }
  ]

function Languageselector(){
  const {t} = useTranslation()
  const [isClicked, setIsClicked] = useState(false);
    return(
        <div>

        <div class="btn-group">
        <button
  className={`btn btn-secondary btn-sm dropdown-toggle ${isClicked ? 'show' : ''}`}
  type="button"
  data-bs-toggle="dropdown"
  aria-expanded={isClicked}
  onClick={() => setIsClicked(!isClicked)}
>
  {t("language")}
</button>
<ul className={`dropdown-menu ${isClicked ? 'show' : 'hidden'}`}>
  {
    languages.map(({ code, name }) => (
      <li key={code}><button className="dropdown-item" onClick={() => i18n.changeLanguage(code)}>
        {name}
      </button></li>
    ))
  }

</ul>
</div>
    
        </div>

        
        
    )
}

export default Languageselector;