import React, { useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { QueryClient, QueryClientProvider } from 'react-query';
import './RegisterForm.css';
import alert from "../images/create_alert.svg";

const queryClient = new QueryClient();
const token = localStorage.getItem('token');

function AlertCategoryForm() {
  const fetchCategories = async () => {
    const response = await axios.get('https://admin.cheretanet.com/api/categories', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    });
    return response.data;
  };

  const { data: categories, isLoading, error } = useQuery('categories', fetchCategories);
  const [alertCreationStatus, setAlertCreationStatus] = useState('');
  const userId = localStorage.getItem('user_id');


  const createAlertCategory = async (categoryId) => {
    try {
      const response = await axios.post(
        'https://admin.cheretanet.com/api/alert',
        {
          category_id: categoryId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        }
      );

      // Handle the response data or perform additional actions
      console.log(response.data);
      setAlertCreationStatus('Alert created successfully!');
    } catch (error) {
      console.error(error);
      setAlertCreationStatus('Error occurred while creating the alert.');
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (userId) {
      // Call the createAlertCategory function with the selected category ID
      createAlertCategory(e.target.categoryId.value);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error occurred while fetching categories.</div>;
  }

  return (
    <div className='compform'>
         <img src={alert} alt="FAQ" className="comp__image" />
      <form onSubmit={handleFormSubmit} className='RegisterFormClass'>
        <h2>Create Alert</h2>
        {userId ? (
          
         
          <div>
             <br/>
          <br/>
            {alertCreationStatus && <p>{alertCreationStatus}</p>}
            <label>
              Category ID:
              <select name="categoryId">
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </label>
            <div> 
            <button type="submit" className='btn3'>Create</button></div>
          </div>
        ) : (
          <p>User ID not found. Please log in first.</p>
        )}
      </form>
   
    </div>
  );
}

function AlertCat() {
  return (
    <QueryClientProvider client={queryClient}>
      <AlertCategoryForm />
    </QueryClientProvider>
  );
}

export default AlertCat;

// import React from 'react';
// import axios from 'axios';
// import { useQuery } from 'react-query';
// import { QueryClient, QueryClientProvider } from 'react-query';
// import './RegisterForm.css';

// const queryClient = new QueryClient();
// const token = localStorage.getItem('token');

// function AlertCategoryForm() {
//   const fetchCategories = async () => {
//     const response = await axios.get('https://admin.cheretanet.com/api/categories', {
//       headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json',
//         'Authorization': 'Bearer ' + token
//       }
//     });
//     return response.data;
//   };

//   const { data: categories, isLoading, error } = useQuery('categories', fetchCategories);

//   const createAlertCategory = async (categoryIds) => {
//     try {
//       const response = await axios.post(
//         'https://admin.cheretanet.com/api/alert',
//         {
//           category_ids: categoryIds,
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + token,
//           },
//         }
//       );
  
//       // Handle the response data or perform additional actions
//       console.log(response.data);
//     } catch (error) {
//       console.error(error);
//       // Handle the error or display an error message
//     }
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     const selectedCategories = Array.from(e.target.elements)
//       .filter((element) => element.type === 'checkbox' && element.checked)
//       .map((element) => element.value);
//     createAlertCategory(selectedCategories);
//   };

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error occurred while fetching categories.</div>;
//   }

//   return (
//     <div>
//       <form onSubmit={handleFormSubmit} className='RegisterFormClass'>
//         <h2>Create Alert</h2>
//         {categories.map((category) => (
//           <label key={category.id}>
//             {category.name}:
//             <input
//               type="checkbox"
//               name="categoryId"
//               value={category.id}
//             />
//           </label>
//         ))}
//         <button type="submit" className='btn3'>Create</button>
//       </form>
//     </div>
//   );
// }

// function AlertCat() {
//   return (
//     <QueryClientProvider client={queryClient}>
//       <AlertCategoryForm />
//     </QueryClientProvider>
//   );
// }

// export default AlertCat;