import { Routes } from 'react-router-dom';
import './index.css';
import React, { useEffect } from 'react';
import { BrowserRouter,  Route, Link } from 'react-router-dom';
import Header from './Components/Header'
import Aboutus from './Components/Aboutus'
import Footer from './Components/Footer' 
import Contactus from './Components/Contactus'
import Home from './Components/Home'
import Settings from './Components/Settings';
import PostTender from "./Components/PostTender.js";
import EditProfile from "./Components/EditProfile"
import Login from './Components/Login.js';
import PaymentForm from "./Components/PaymentForm" ;
import Forgetpassword from "./Components/Forgetpassword" ;
import Phonebook from "./Components/Phonebook.js"
import CompanyReg from "./Components/CompanyReg.js"
import Register from "./Components/Register"
import FreeTender from "./Components/FreeTender"
import SavedTender from "./Components/SavedTender"
import AlertCat from "./Components/AlertCat.js"
import AlertDisplay from "./Components/AlertDisplay.js"
import Job from "./Components/Job.js"

function App() {
 
  
  return (
    <div className="App">
    
 <BrowserRouter>
      <Header />
      <Routes>
        <Route  path="/" element={<FreeTender/>} />
        <Route  path="/Aboutus.js" element={<Aboutus/>} />
        <Route  path = "/Contactus.js" element = {<Contactus/>}/> 
        <Route  path = "/Settings.js" element = {<Settings/>}/> 
        <Route  path = "/PostTender.js" element = {<PostTender/>}/> 
        <Route  path = "/EditProfile.js" element = {<EditProfile/>}/> 
        <Route  path = "/Login.js" element = {<Login/>}/> 
        <Route  path = "/PaymentForm.js" element = {<PaymentForm/>}/>         
        <Route  path = "/Forgetpassword.js" element = {<Forgetpassword/>}/> 
        <Route  path = "/Phonebook.js" element = {<Phonebook/>}/> 
        <Route  path = "/CompanyReg.js" element = {<CompanyReg/>}/> 
        <Route  path = "/Register.js" element = {<Register/>}/> 
        <Route  path = "/Home.js" element = {<Home/>}/> 
        <Route  path = "/SavedTender.js" element = {<SavedTender/>}/> 
        <Route  path = "/AlertCat.js" element = {<AlertCat/>}/> 
        <Route  path = "/AlertDisplay.js" element = {<AlertDisplay/>}/> 

        <Route  path = "/Job.js" element = {<Job/>}/> 
        <Route  path = "/PaymentForm.js" element = {<PaymentForm/>}/> 

        
        
      </Routes>
       <Footer/>
    </BrowserRouter>  




    </div>
  );

// return (
// <div> 

// <PaymentForm/>
// </div>
// )

}

export default App;

