import React, { useState } from 'react';
import './RegisterForm.css';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import Home from './Home.js'
import { useNavigate } from 'react-router-dom';
import login from "../images/login.svg";
const Login = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const handleFormSubmit = async (e) => {
    e.preventDefault();
  

    try {
      const response = await fetch('https://admin.cheretanet.com/api/logins', {
        
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
         
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (response.ok) {
        const responseData = await response.clone().json();

        // Extract the id value from the response data
        const userId = responseData.data.id;
        const token = responseData.token;
        setToken(token);
        localStorage.setItem('user_id', userId);
        // Store the user ID in Local Storage
        localStorage.setItem('token', token);

        // Log the retrieved User ID
        console.log('Retrieved User ID:', userId);
        console.log('Retrieved Token:', token);
        // Perform desired actions or redirect to another page
        window.location.href= ('/Home.js')
       //navigate('/Home.js');     

        setTimeout(() => {
          localStorage.removeItem('user_id');
        }, 12 * 60 * 60 * 1000); // 12 hours in milliseconds
      } else {
        const data = await response.json();
        const errorMessage = data.message || 'An error occurred during login';
        setError(errorMessage); // Set the error message received from the server or a generic error message
      }
    } catch (error) {
      console.error('An error occurred during login:', error);
      setError('An error occurred during login'); // Set a generic error message
    }
  };

  return (
    <div className='compform'>
       <img src={login} className="comp__image" alt="logo" />
      <form onSubmit={handleFormSubmit} className='RegisterFormClass'> 
      <h2>{t("login")}</h2><br/>
      {error && <div className="error-message">{error}</div>}
      <br/>
        <label htmlFor='email'>{t("email")}:</label>
        <input
          type='email'
          id='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor='password'>{t("password")}:</label>
        <input
          type='password'
          id='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button type='submit' className='btn3'>
          {t("login")}
        </button>
        <Link to='/Forgetpassword.js'>{t("forgetpass")}</Link>
      </form>
    </div>
  );
};

export default Login;